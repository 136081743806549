import { useLocation } from '@reach/router';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { parse } from 'query-string';
import React, { Dispatch, SetStateAction } from 'react';
import { NotificationManager } from 'react-notifications';

import useAuth from '../../../../hooks/useAuth';
import useViewport from '../../../../hooks/useViewport';
import accountIcon from '../../../../images/icon-account.svg';
import ArrowButton from '../../arrow-button/ArrowButton';
import { ExpandType } from '../../enum/expand-type.enum';
import FieldError from '../field-error/FieldError';
import { validateConfirmPassword, validatePassword } from '../validators';
import styles from './ResetPassword.module.scss';

interface Values {
  password: string;
  passwordConfirmation: string;
}

interface Props {
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
}

const ResetPassword: React.FC<Props> = ({ setExpandType }: Props) => {
  const { isTablet } = useViewport();
  const location = useLocation();

  const { code } = parse(location.search);

  const { resetPassword } = useAuth();

  const onSubmit = async ({ password, passwordConfirmation }: Values) => {
    try {
      await resetPassword({ code: code as string, password, passwordConfirmation });
      NotificationManager.success('Password successfully changed');
      setExpandType(ExpandType.NONE);
      return true;
    } catch (e) {
      NotificationManager.warning(e);
      return false;
    }
  };

  return (
    <>
      <section className={styles.resetPassword}>
        {isTablet && (
          <div className={styles.mobileHeader}>
            <img alt="account icon" src={accountIcon} />
            RESET PASSWORD
          </div>
        )}
        <Formik
          initialValues={{
            passwordConfirmation: '',
            password: '',
          }}
          onSubmit={async (values, { resetForm }) => {
            const success = await onSubmit(values);
            if (success) {
              resetForm();
            }
          }}
        >
          {({ values, errors, touched }) => (
            <Form className={classnames(styles.formContainer)}>
              <div
                className={classnames(
                  styles.withTextError,
                  touched.password && errors.password && styles.error,
                )}
              >
                <Field
                  className={styles.formItemField}
                  name="password"
                  placeholder="Password"
                  type="password"
                  validate={validatePassword}
                />
                <FieldError name="password" />
              </div>

              <div
                className={classnames(
                  styles.withTextError,
                  touched.passwordConfirmation && errors.passwordConfirmation && styles.error,
                )}
              >
                <Field
                  className={styles.formItemField}
                  name="passwordConfirmation"
                  placeholder="Confirm password"
                  type="password"
                  validate={(value: string) => validateConfirmPassword(values.password, value)}
                />
                <FieldError name="passwordConfirmation" />
              </div>

              <button type="submit" className={styles.buttonContainer}>
                <ArrowButton
                  isWhite={isTablet ? true : false}
                  name="RESET"
                  fontSize={isTablet ? '2rem' : '1.6rem'}
                  imgHeight={'2.5rem'}
                  imgLeftMargin={'1.5rem'}
                />
              </button>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};

export default ResetPassword;
