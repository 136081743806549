import React from 'react';
import styles from './ArrowButton.module.scss';
import iconBlackDesktop from '../../../images/Desktop_Black.svg';
import iconWhiteDesktop from '../../../images/Desktop_White.svg';
import iconBlackMobile from '../../../images/Mobile_Black.svg';
import iconWhiteMobile from '../../../images/Mobile_White.svg';
import useViewport from '../../../hooks/useViewport';
import classNames from 'classnames';
interface Props {
  noImage?: boolean;
  name?: string;
  imgLeftMargin?: string;
  imgHeight?: string;
  fontSize?: string;
  isWhite?: boolean;
  popUp?: boolean;
  onClick?: () => void;
  nameClass?: string;
  className?: string;
}

const ArrowButton: React.FC<Props> = ({
  noImage,
  popUp,
  name,
  imgLeftMargin,
  imgHeight,
  fontSize,
  isWhite,
  onClick,
  nameClass,
  className,
}: Props) => {
  const { isTablet } = useViewport();
  const whiteIcon = isTablet ? iconWhiteMobile : iconWhiteDesktop;
  const blackIcon = isTablet ? iconBlackMobile : iconBlackDesktop;
  return (
    <div
      className={`${styles.ArrowButton} ${className}`}
      style={
        !popUp
          ? {
              color: isWhite ? 'white' : 'black',
              fontSize,
            }
          : {
              color: isWhite ? 'white' : 'black',
              fontSize,
              maxWidth: '5rem',
              marginLeft: '24rem',
            }
      }
      onClick={onClick}
    >
      <div className={nameClass}>{name}</div>
      {!noImage && (
        <img
          alt="contact icon"
          style={{
            marginLeft: imgLeftMargin,
            height: imgHeight,
            maxHeight: imgHeight,
          }}
          src={isWhite ? whiteIcon : blackIcon}
        />
      )}
    </div>
  );
};

export default ArrowButton;
