import { useEffect, useState } from 'react';

const MOBILE_BREAKPOINT = 500;
const TABLET_BREAKPOINT = 800;
const DESKTOP_SIZE = 1366;

class Viewport {
  constructor(width: number) {
    this.width = width;
  }

  public width: number;

  public get isMobile(): boolean {
    return this.width <= MOBILE_BREAKPOINT;
  }

  public get isTablet(): boolean {
    return this.width <= TABLET_BREAKPOINT;
  }

  public get isServer(): boolean {
    return typeof window === 'undefined';
  }
}

function getWindowWidth() {
  return typeof window === 'undefined' ? DESKTOP_SIZE : window.innerWidth;
}

function useViewport(triggerUpdate?: boolean): Viewport {
  const [viewport, setViewport] = useState(new Viewport(getWindowWidth()));

  useEffect(() => {
    function handleResize(): void {
      setViewport(new Viewport(getWindowWidth()));
    }
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, [triggerUpdate]);

  return viewport;
}

export default useViewport;
