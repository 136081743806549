import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';

import useAuth from '../../../../hooks/useAuth';
import useViewport from '../../../../hooks/useViewport';
import accountIcon from '../../../../images/icon-account.svg';
import ArrowButton from '../../../shared/arrow-button/ArrowButton';
import { ExpandType } from '../../enum/expand-type.enum';
import FieldError from '../field-error/FieldError';
import { validateEmail, validatePassword } from '../validators';
import styles from './LogIn.module.scss';

interface Values {
  email: string;
  password: string;
}

interface Props {
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
}

const LogIn: React.FC<Props> = ({ setExpandType }: Props) => {
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  useEffect(() => setTriggerUpdate(true), []);

  const { isTablet, isServer } = useViewport(triggerUpdate);

  const { login } = useAuth();

  const onSubmit = async ({ email, password }: Values) => {
    try {
      await login({ identifier: email, password });
      navigate('/account/favorites');
      return true;
    } catch (e) {
      NotificationManager.warning('No Account Found. Please create an account.');
      return false;
    }
  };

  return (
    <>
      <section className={styles.logIn}>
        <div className={classnames(styles.mobileHeader, 'hide-on-desktop')}>
          <img alt="account icon" src={accountIcon} />
          LOG IN
        </div>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async (values, { resetForm }) => {
            const success = await onSubmit(values);
            if (success) {
              resetForm();
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className={classnames(styles.formContainer)}>
              <div
                className={classnames(
                  styles.withTextError,
                  touched.email && errors.email && styles.error,
                )}
              >
                <Field
                  className={styles.formItemField}
                  name="email"
                  placeholder="Email"
                  type="email"
                  validate={validateEmail}
                />
                <FieldError name="email" />
              </div>

              <div
                className={classnames(
                  styles.withTextError,
                  touched.password && errors.password && styles.error,
                )}
              >
                <Field
                  className={styles.formItemField}
                  name="password"
                  placeholder="Password"
                  type="password"
                  validate={validatePassword}
                />
                <FieldError name="password" />
              </div>

              {!isServer && (
                <button type="submit" className={styles.buttonContainer}>
                  <ArrowButton
                    isWhite={isTablet ? true : false}
                    name={'LOG IN'}
                    fontSize={isTablet ? '1.8rem' : '1.6rem'}
                    imgHeight={isTablet ? '2rem' : '2.5rem'}
                    imgLeftMargin={'1.5rem'}
                  />
                </button>
              )}
            </Form>
          )}
        </Formik>
        <div className={styles.links}>
          <button
            className={styles.link}
            onClick={() => {
              setExpandType(ExpandType.FORGOT_PASSWORD);
            }}
          >
            Forget your password?
          </button>
          {!isServer && (
            <button
              className={styles.link}
              onClick={() => {
                setExpandType(ExpandType.CREATE_ACCOUNT);
              }}
            >
              Don`t have an account?
              {isTablet ? <br /> : ' '}
              Sign up now!
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default LogIn;
