import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import styles from './ForgotPassword.module.scss';
import ArrowButton from '../../../shared/arrow-button/ArrowButton';
import { Formik, Field, Form } from 'formik';

import backArrow from '../../../../images/black-arrow-left.svg';
import { ExpandType } from '../../enum/expand-type.enum';
import useViewport from '../../../../hooks/useViewport';
import useAuth from '../../../../hooks/useAuth';
import { NotificationManager } from 'react-notifications';
import SPINNER from '../../../../images/spinner-icon.jpg';
import cn from 'classnames';

interface Props {
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
}

const ForgotPassword: React.FC<Props> = ({ setExpandType }: Props) => {
  const { isTablet, isServer } = useViewport();
  const { forgotPassword } = useAuth();
  const [isSpinnerOn, setSpinner] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(!isDisabled);
  }, [isSpinnerOn, setSpinner]);

  return (
    <section className={styles.forgotPassword}>
      {!isServer &&
        (isTablet ? (
          <span className={styles.mobileHeader}>FORGET PASSWORD</span>
        ) : (
          <button
            onClick={() => {
              setExpandType(ExpandType.LOG_IN);
            }}
            className={styles.backButton}
          >
            <img alt="back" src={backArrow} className={styles.backArrow} />
            <span className={styles.back}>BACK</span>
          </button>
        ))}
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            await forgotPassword(values);
            NotificationManager.success('Password reset instructions were sent to your email');
            setSpinner(false);
          } catch (e) {
            NotificationManager.warning('Please enter your email');
            setSpinner(false);
          }
          resetForm();
        }}
      >
        <Form className={styles.formContainer}>
          <div className={styles.formFields}>
            <Field
              className={styles.formItemField + ' ' + styles.wide}
              id="email"
              name="email"
              placeholder="Email"
              type="email"
            />
          </div>
          <span className={styles.text}>We will send you an email to reset your password.</span>
          <div style={{ marginTop: '0.5rem' }}>
            {!isServer && (
              <button
                type="submit"
                className={styles.buttonContainer}
                disabled={!isDisabled}
                style={isSpinnerOn ? { filter: 'contrast(5%)' } : {}}
                onClick={() => (!isSpinnerOn ? setSpinner(true) : null)}
              >
                <ArrowButton
                  isWhite={isTablet ? true : false}
                  name={'SUBMIT'}
                  fontSize={'1.6rem'}
                  imgHeight={'2.5rem'}
                  imgLeftMargin={'1.5rem'}
                />
              </button>
            )}
          </div>
        </Form>
      </Formik>
      {isSpinnerOn && (
        <div className={styles.spinnerWrapper}>
          <img
            src={SPINNER}
            alt="spinner"
            className={cn(styles.spinner, { [styles.rotating]: isSpinnerOn })}
          />
        </div>
      )}
    </section>
  );
};

export default ForgotPassword;
