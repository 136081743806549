import axios from 'axios';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { Link } from 'gatsby';
// import React from 'react';
import { NotificationManager } from 'react-notifications';

import { API_URL } from '../../../config';
import logo from '../../../images/DVM_Logo_white.svg';
import facebook from '../../../images/facebook.svg';
import houzz from '../../../images/houzz.svg';
import instagram from '../../../images/instagram.svg';
import pinterest from '../../../images/pinterest.svg';
import twitter from '../../../images/twitter.svg';
import linkedin from '../../../images/linkedin.svg';
import FieldError from '../../shared/forms/field-error/FieldError';
import { validateEmail } from '../../shared/forms/validators';
import styles from './footer.module.scss';
import { string } from 'prop-types';

const Footer: React.FC<Props> = (props: Props) => {
  const onSubmit = async (data: { email: string; name: string }) => {
    try {
      await axios.post(`${API_URL}/email-subscriptions`, data);
      NotificationManager.success('You successfully subscribed!');
      return true;
    } catch (e) {
      NotificationManager.warning(e);
      return false;
    }
  };

  return (
    <footer style={{ position: 'relative', zIndex: 11 }}>
      <div className={styles.leftPanelBox}>
        <Link to="/" className={styles.logo}>
          <img alt="logo" src={logo} />
        </Link>
        <div className={styles.socialNetworksBoxMobile}>
          <SocialLinks />
        </div>
        <p className={styles.copyrightDesktop}>
          © 2024 Da Vinci Marble.
          <br />
          All rights reserved.<br></br>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </p>
      </div>
      <div className={styles.navBox}>
        <Link to="/about" className={styles.navLink}>
          ABOUT
        </Link>
        {/* <Link to="/showroom" className={styles.navLink}>
          SHOWROOM
        </Link> */}
        <Link to="/resources" className={styles.navLink}>
          RESOURCES
        </Link>
        <Link to="/slab" className={styles.navLink}>
          SLAB INVENTORY
        </Link>
        <Link to="/tile" className={styles.navLink}>
          TILE
        </Link>
        <Link to="/custom" className={styles.navLink}>
          CUSTOM
        </Link>
        <Link to="/portfolio" className={styles.navLink}>
          PORTFOLIO
        </Link>
        <Link to="/careers" className={styles.navLink}>
          CAREERS
        </Link>
      </div>
      <div className={styles.businessInfoBox}>
        <div className={styles.businessHoursBox}>
          <h4>Business Hours</h4>
          <p>Monday – Friday: 8AM to 5PM</p>
          <p>Saturday: Closed</p>
          <p>Sunday: Closed</p>
        </div>
        <div className={styles.contactUsBox}>
          <h4>Contact Us</h4>
          <p>650-595-2500</p>
          <p>info@davincimarble.com</p>
          <p>1480 Industrial Rd.</p>
          <p>San Carlos, CA 94070</p>
        </div>
      </div>
      <div className={styles.rightActionsBox}>
        <div className={styles.subscribeBox}>
          <h4>Subscribe to Stay in Touch</h4>

          <Formik
            initialValues={{
              email: '',
              name: '',
            }}
            onSubmit={async (values, { resetForm }) => {
              const success = await onSubmit(values);
              if (success) {
                resetForm();
              }
            }}
          >
            {({ errors, touched }) => (
              <Form className={classnames(styles.formContainer)}>
                <div
                  className={classnames(
                    styles.withTextError,
                    touched.name && errors.name && styles.error,
                  )}
                >
                  <Field
                    className={styles.formItemField}
                    name="name"
                    placeholder="Name"
                    type="text"
                    validate={(value: string) => !value && 'Name is required'}
                  />
                  <FieldError name="name" />
                </div>

                <div
                  className={classnames(
                    styles.withTextError,
                    touched.email && errors.email && styles.error,
                  )}
                >
                  <Field
                    className={styles.formItemField}
                    name="email"
                    placeholder="Email"
                    type="email"
                    validate={validateEmail}
                  />
                  <FieldError name="email" />
                </div>

                <button type="submit" className={styles.buttonContainer}>
                  <span>Subscribe</span>
                  <div className={styles.arrow}></div>
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <StaticQuery
          query={graphql`
            query {
              strapi {
                footer {
                  latestNewsUrl
                }
              }
            }
          `}
          render={(data) => (
            <a
              className={
                data.strapi.footer.latestNewsUrl !== '' ? styles.latestNewsLink : styles.hidden
              }
              href={data.strapi.footer.latestNewsUrl}
              rel="noreferrer"
              target="_blank"
            >
              See Our Latest News
            </a>
          )}
        />
        <div className={styles.socialNetworksBoxDesktop}>
          <SocialLinks></SocialLinks>
        </div>
        <p className={styles.copyrightMobile}>
          © 2024 Da Vinci Marble.<br></br>
          All rights reserved.<br></br>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </p>
      </div>
    </footer>
  );
};

class SocialLinks extends React.Component {
  render(): React.ReactNode {
    return (
      <>
        <a href="https://www.instagram.com/davincimarble/" target="_blank" rel="noreferrer">
          <img alt="instagram" src={instagram}></img>
        </a>
        <a
          href="https://www.facebook.com/davincimarbleinc/?ref=ts"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="facebook" src={facebook}></img>
        </a>
        <a href="https://www.linkedin.com/company/da-vinci-marble" target="_blank" rel="noreferrer">
          <img alt="linkedin" src={linkedin}></img>
        </a>
        <a href="https://www.pinterest.com/davincimarble/" target="_blank" rel="noreferrer">
          <img alt="pinterest" src={pinterest}></img>
        </a>
        <a
          href="https://www.houzz.com/professionals/tile-stone-and-countertops/da-vinci-marble-pfvwus-pf~1224880627"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="houzz" src={houzz}></img>
        </a>
      </>
    );
  }
}

export default Footer;
