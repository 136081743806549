export enum ExpandType {
  MENU = 'menu',
  SEARCH = 'search',
  CONTACT_US = 'contact_us',
  CONTACT_US_POPUP = 'contact_us_pupup',
  CREATE_ACCOUNT = 'create_account',
  LOG_IN = 'log_in',
  FORGOT_PASSWORD = 'forgot_password',
  RESET_PASSWORD = 'reset_password',
  NONE = 'none',
}
