import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import React, { Dispatch, SetStateAction } from 'react';
import useAuth from '../../../../hooks/useAuth';
import useViewport from '../../../../hooks/useViewport';
import backArrow from '../../../../images/black-arrow-left.svg';
import accountIcon from '../../../../images/icon-account.svg';
import ArrowButton from '../../../shared/arrow-button/ArrowButton';
import { ExpandType } from '../../enum/expand-type.enum';
import FieldError from '../field-error/FieldError';
import {
  validateCompanyName,
  validateConfirmPassword,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from '../validators';
import styles from './CreateAccount.module.scss';
import { NotificationManager } from 'react-notifications';

interface Values {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
}

const CreateAccount: React.FC<Props> = ({ setExpandType }: Props) => {
  const { isTablet } = useViewport();
  const { register } = useAuth();

  const onSubmit = async ({ email, password, company, firstName, lastName }: Values) => {
    try {
      await register({
        email,
        firstName,
        lastName,
        password,
        username: `${firstName} ${lastName}`,
        company,
      });
      navigate('/account/favorites');
      return true;
    } catch (e) {
      NotificationManager.warning(e);
      return false;
    }
  };

  return (
    <section className={styles.createAccount}>
      {isTablet ? (
        <div className={styles.mobileHeader}>
          <img alt="account icon" src={accountIcon} />
          SIGN UP
        </div>
      ) : (
        <button
          onClick={() => {
            setExpandType(ExpandType.LOG_IN);
          }}
          className={styles.backButton}
        >
          <img alt="back" src={backArrow} className={styles.backArrow} />
          <span className={styles.back}>BACK</span>
        </button>
      )}
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          company: '',
          email: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const success = await onSubmit(values);
          if (success) {
            resetForm();
          }
        }}
      >
        {({ errors, values, touched }) => (
          <Form className={styles.formContainer}>
            <div className={styles.formFields}>
              <Field
                className={classnames(
                  styles.formItemField,
                  touched.firstName && errors.firstName && styles.error,
                )}
                name="firstName"
                placeholder="First name"
                validate={validateFirstName}
              />
              <Field
                className={classnames(
                  styles.formItemField,
                  touched.lastName && errors.lastName && styles.error,
                )}
                name="lastName"
                placeholder="Last name"
                validate={validateLastName}
              />
              <Field
                className={classnames(
                  styles.formItemField,
                  touched.company && errors.company && styles.error,
                )}
                name="company"
                placeholder="Company name"
                validate={validateCompanyName}
              />
              <Field
                className={classnames(
                  styles.formItemField,
                  styles.wide,
                  touched.email && errors.email && styles.error,
                )}
                name="email"
                placeholder="Email"
                type="email"
                validate={validateEmail}
              />
              <div
                className={classnames(
                  styles.withTextError,
                  touched.password && errors.password && styles.error,
                )}
              >
                <Field
                  className={classnames(styles.formItemField, styles.extraWide)}
                  name="password"
                  placeholder="Password"
                  type="password"
                  validate={validatePassword}
                />
                <FieldError name="password" />
              </div>
              <div
                className={classnames(
                  styles.withTextError,
                  touched.confirmPassword && errors.confirmPassword && styles.error,
                )}
              >
                <Field
                  className={classnames(styles.formItemField, styles.extraWide)}
                  name="confirmPassword"
                  placeholder="Confirm password"
                  type="password"
                  validate={(value: string) => validateConfirmPassword(values.password, value)}
                />
                <FieldError name="confirmPassword" />
              </div>
            </div>
            <button type="submit" className={styles.buttonContainer}>
              <ArrowButton
                isWhite={isTablet}
                name={'CREATE AN ACCOUNT'}
                fontSize={'1.6rem'}
                imgHeight={'2.5rem'}
                imgLeftMargin={'1.5rem'}
              />
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default CreateAccount;
