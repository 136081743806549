import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

// eslint-disable-next-line
export default class Portal extends Component<any> {
  // eslint-disable-next-line
  el: any;
  // eslint-disable-next-line
  constructor(props: any) {
    super(props);
    // Use a ternary operator to make sure that the document object is defined
    this.el = typeof document !== `undefined` ? document.createElement('div') : null;
  }

  componentDidMount = (): void => {
    portalRoot?.appendChild(this.el);
  };

  componentWillUnmount = (): void => {
    portalRoot?.removeChild(this.el);
  };

  render(): React.ReactNode {
    const { children } = this.props;

    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(children, this.el);
    } else {
      return null;
    }
  }
}
