import { Link, navigate } from 'gatsby';
import React, { Dispatch, SetStateAction } from 'react';
import facebook from '../../images/facebook-black.svg';
import houzz from '../../images/houzz-black.svg';
import MobileOverlayMenu from './mobile-overlay-menu/MobileOverlayMenu';
import accountIcon from '../../images/icon-account-black.svg';
import searchIcon from '../../images/icon-search-black.svg';
import instagram from '../../images/instagram-black.svg';
import linkedin from '../../images/linkedin-icon-black.svg';
import pinterest from '../../images/pinterest-black.svg';
import twitter from '../../images/twitter-black.svg';
import yelp from '../../images/yelp-icon-black.svg';
import { ExpandType } from '../shared/enum/expand-type.enum';
import styles from './OverlayMenu.module.scss';
import useAuth from '../../hooks/useAuth';
import useViewport from '../../hooks/useViewport';

interface Props {
  expandType: ExpandType;
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
  isHomePage: boolean;
}

const OverlayMenu: React.FC<Props> = ({ expandType, setExpandType, isHomePage }: Props) => {
  const { isAuthenticated } = useAuth();
  const { isTablet, isMobile } = useViewport();

  const openAccount = () => {
    if (isAuthenticated) {
      navigate('/account/favorites');
      setExpandType(ExpandType.NONE);
    } else {
      setExpandType(ExpandType.LOG_IN);
    }
  };

  return (
    <>
      <div className={styles.mobileMenu}>
        <MobileOverlayMenu expandType={expandType} setExpandType={setExpandType} />
      </div>
      <div className={styles.desktopMenu}>
        <div
          className={styles.leftSideBox}
          style={{
            transform: expandType === ExpandType.MENU ? 'translateX(0)' : 'translateX(-100%)',
          }}
        >
          <div
            className={styles.menuItemsBox}
            style={{
              maxHeight: !isMobile && !isTablet && isHomePage ? '75vh' : undefined,
              overflowY: 'auto',
            }}
          >
            <span className={styles.menuCopy}>MENU</span>
            <Link
              className={styles.menuItem}
              to="/slab"
              data-before="01"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              SLAB INVENTORY
            </Link>
            <Link
              className={styles.menuItem}
              to="/tile"
              data-before="02"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              TILE
            </Link>
            <Link
              className={styles.menuItem}
              to="/custom"
              data-before="03"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              CUSTOM
            </Link>
            <Link
              className={styles.menuItem}
              to="/portfolio"
              data-before="04"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              PORTFOLIO
            </Link>
            <div className={styles.spacer}></div>
            <Link
              className={styles.menuItem}
              to="/about"
              data-before="05"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              ABOUT
            </Link>
            {/* <Link
              className={styles.menuItem}
              to="/showroom"
              data-before="06"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              SHOWROOM
            </Link> */}
            <Link
              className={styles.menuItem}
              to="/resources"
              data-before="06"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              RESOURCES
            </Link>
            <Link
              className={styles.menuItem}
              to="/careers"
              data-before="07"
              onClick={() => setExpandType(ExpandType.NONE)}
            >
              CAREERS
            </Link>
          </div>
        </div>
        <div
          className={styles.rightSideBox}
          style={{
            transform: expandType === ExpandType.MENU ? 'translateX(0)' : 'translateX(100%)',
            transitionDelay: expandType !== ExpandType.MENU ? '0s' : '0.3s',
          }}
        >
          <div className={styles.topBox}>
            <button
              className={styles.contactUs}
              onClick={() => setExpandType(ExpandType.CONTACT_US)}
            >
              CONTACT US
            </button>
            <button onClick={openAccount} className={styles.accountButton}>
              <img alt="account icon" src={accountIcon} />
            </button>
            <button
              className={styles.searchButton}
              onClick={() => setExpandType(ExpandType.SEARCH)}
            >
              <img alt="search icon" src={searchIcon} />
            </button>
          </div>
          <div className={styles.bottomBox}>
            <div className={styles.contactUsBox}>
              <h4>Contact Us</h4>
              <p>650-595-2500</p>
              <p>info@davincimarble.com</p>
              <p>1480 Industrial Rd.</p>
              <p>San Carlos, CA 94070</p>
            </div>
            <div className={styles.businessHoursBox}>
              <h4>Business Hours</h4>
              <p>Monday – Friday: 8AM to 5PM</p>
              <p>Saturday: Closed</p>
              <p>Sunday: Closed</p>
            </div>
            <SocialLinks />
          </div>
        </div>
      </div>
    </>
  );
};

class SocialLinks extends React.Component {
  render(): React.ReactNode {
    return (
      <div className={styles.socialLinksBox}>
        <div className={styles.flexConatiner}>
          <a
            href="https://www.facebook.com/davincimarbleinc/?ref=ts"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="facebook" src={facebook} />
          </a>
          <a href="https://www.instagram.com/davincimarble/" target="_blank" rel="noreferrer">
            <img alt="instagram" src={instagram} />
          </a>
          <a href="https://twitter.com/DaVinciMarble" target="_blank" rel="noreferrer">
            <img alt="twitter" src={twitter} />
          </a>
          <a
            href="https://www.yelp.com/biz/da-vinci-marble-san-carlos"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="yelp" src={yelp} />
          </a>
        </div>
        <div className={styles.flexConatiner}>
          <a
            href="https://www.linkedin.com/company/da-vinci-marble/"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="linkedin" src={linkedin} />
          </a>
          <a href="https://www.pinterest.com/davincimarble/" target="_blank" rel="noreferrer">
            <img alt="pinterest" src={pinterest} />
          </a>
          <a
            href="https://www.houzz.com/professionals/tile-stone-and-countertops/da-vinci-marble-pfvwus-pf~1224880627"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="houzz" src={houzz} />
          </a>
        </div>
      </div>
    );
  }
}

export default OverlayMenu;
