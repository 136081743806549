import React, { useState } from 'react';
import styles from './ContactUsPopup.module.scss';
import closeIcon from '../../../images/close-black.svg';
import backArrowIcon from '../../../images/back-arrow-black.svg';

import ReusableContactForm from '../contact-form/ReusableContactForm';
import useViewport from '../../../hooks/useViewport';
import { Modal } from '../modal/Modal';

interface Props {
  onBack?: () => void;
  onClose?: () => void;
}

const ContactUsPopup: React.FC<Props> = (props: Props) => {
  const [favoritesModalState, setFavoritesModalState] = useState(true);
  const { isMobile } = useViewport();

  const toggleFavoritesModal = (): void => {
    setFavoritesModalState(!favoritesModalState);
  };

  return (
    <div>
      {isMobile ? (
        <Modal isOpen={favoritesModalState} handleClose={toggleFavoritesModal} contactUs={true}>
          <div className={styles.ContactUsPopup}>
            <div className={styles.headerButtons}>
              <div className={styles.back} onClick={props.onBack}>
                <img alt="back icon" src={backArrowIcon} />
                <div> BACK TO CONTACT US</div>
              </div>
              <img
                className={styles.close}
                alt="close icon"
                src={closeIcon}
                onClick={props.onClose!}
              />
            </div>

            <div className={styles.content}>
              <div className={styles.formName}>ASK US ANYTHING!</div>
              <ReusableContactForm popUp={true} />
            </div>
          </div>
        </Modal>
      ) : (
        <div className={styles.ContactUsPopup}>
          <div className={styles.headerButtons}>
            <div className={styles.back} onClick={props.onBack}>
              <img alt="back icon" src={backArrowIcon} />
              <div> BACK TO CONTACT US</div>
            </div>
            <img
              className={styles.close}
              alt="close icon"
              src={closeIcon}
              onClick={props.onClose}
            />
          </div>

          <div className={styles.content}>
            <div className={styles.formName}>ASK US ANYTHING!</div>
            <ReusableContactForm popUp={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUsPopup;
