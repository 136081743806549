export function validateEmail(value: string): string | undefined {
  let error;
  if (!value) {
    error = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

export function validateFirstName(name: string): string | undefined {
  if (!name) {
    return 'First name is required!';
  } else if (!/^[a-zA-Z ]*$/.test(name)) {
    return 'Invalid name';
  }
}

export function validateLastName(name: string): string | undefined {
  if (!name) {
    return 'Last name is required!';
  } else if (!/^[a-zA-Z ]*$/.test(name)) {
    return 'Invalid name';
  }
}

export function validatePhone(phone: string): string | undefined {
  if (!phone) {
    return 'Phone is required!';
  } else if (phone.length < 8 || !/^[0-9]*$/.test(phone)) {
    return 'Invalid number';
  }
}

export function validatePassword(password: string): string | undefined {
  if (!password) {
    return 'Password is required!';
  } else if (password.length < 6) {
    return 'Password should be minimum 6 characters length!';
  }
}

export function validateConfirmPassword(
  password: string,
  confirmPassword: string,
): string | undefined {
  if (!password) {
    return 'Password confirmation is required!';
  } else if (password !== confirmPassword) {
    return `Passwords don't match!`;
  }
}

export function validateCompanyName(name: string): string | undefined {
  if (!name) {
    return 'Company name is required!';
  }
}
