import React, { useState, useEffect } from 'react';
import ArrowButton from '../arrow-button/ArrowButton';
import styles from './Search.module.scss';

import cn from 'classnames';

const Search: React.FC = () => {
  const [query, updateQuery] = useState<string>('');

  const goToSearchPage = () => {
    typeof window !== 'undefined' &&
      (window.location.href = window.location.origin + `/search?q=${query}`);
  };

  useEffect(() => {
    const headerInput =
      typeof document !== undefined ? document.getElementById('header_search') : null;
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = '0';
    fakeInput.style.height = '0';
    fakeInput.style.fontSize = '16px'; // disable auto zoom

    // you may need to append to another element depending on the browser's auto
    // zoom/scroll behavior
    document.body.prepend(fakeInput);

    // focus so that subsequent async focus will work
    fakeInput.focus();
    

    setTimeout(() => {
      // now we can focus on the target input
      headerInput?.click();
      headerInput?.focus();

      // cleanup
      fakeInput.remove();
    }, 1000);

    // setTimeout(() => { headerInput?.focus()}, 0)
  }, []);

  return (
    <div>
      <section className={styles.Search}>
        <div className={styles.divider}></div>
        <form action="javascript:void(0);" onSubmit={goToSearchPage}>
          <input
            id="header_search"
            type="text"
            placeholder="SEARCH"
            value={query}
            onChange={(e) => updateQuery(e.target.value)}
            className={styles.searchInput}
          />
        </form>
        <div className="hide-on-mobile">
          <ArrowButton name={'SEARCH'} imgLeftMargin={'3rem'} onClick={goToSearchPage} />
        </div>

        <div className="hide-on-desktop">
          <ArrowButton imgLeftMargin={'1rem'} onClick={goToSearchPage} />
        </div>
      </section>
    </div>
  );
};

export default Search;
