import axios from 'axios';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import React from 'react';

import { API_URL } from '../../../config';
import ArrowButton from '../arrow-button/ArrowButton';
import styles from './ReusableContactForm.module.scss';

interface Values {
  name: string;
  firm: string;
  email: string;
  text: string;
}

interface Props {
  popUp?: boolean;
}

function validateEmail(value: string) {
  let error;
  if (!value) {
    error = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

function validateUsername(value: string) {
  let error;
  if (!value) {
    error = 'Name is required';
  } else if (!/^[a-zA-Z ]*$/.test(value)) {
    error = 'Invalid name';
  }
  return error;
}

const ReusableContactForm: React.FC<Props> = ({ popUp }: Props) => {
  const [buttonTitle, setButtonTitle] = React.useState<string>(!popUp ? 'SUBMIT FORM' : 'SUBMIT');
  const [submitedForm, seSubmitedForm] = React.useState<boolean>(false);
  const changeTitleFunction = () => {
    setButtonTitle(popUp ? 'THANK YOU!' : 'THANK YOU!');
    setTimeout(() => {
      setButtonTitle(buttonTitle);
      seSubmitedForm(false);
    }, 3000);
  };

  const onSubmit = async (values: Values) => {
    try {
      await axios.post(`${API_URL}/contact-requests`, values);
      NotificationManager.success('Thank You!');
      return true;
    } catch (e) {
      NotificationManager.warning(e);
      return false;
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          firm: '',
          email: '',
          text: '',
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          seSubmitedForm(true);

          changeTitleFunction();
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.formContainer}>
            <div className={styles.formItemContainer}>
              <label className={styles.formItemLabel} htmlFor="name">
                Name*
              </label>
              <Field
                validate={validateUsername}
                className={styles.formItemField}
                id="name"
                name="name"
                placeholder="enter your name"
              />
            </div>
            {errors.name && touched.name && (
              <div className={styles.errorOnSubmit}>{errors.name}</div>
            )}
            <div className={styles.formItemContainer}>
              <label className={styles.formItemLabel} htmlFor="email">
                Email*
              </label>
              <Field
                validate={validateEmail}
                className={styles.formItemField}
                id="contactEmail"
                name="email"
                placeholder="enter your email"
                type="email"
              />
            </div>
            {errors.email && touched.email && (
              <div className={styles.errorOnSubmit}>{errors.email}</div>
            )}
            <div className={styles.formItemContainer}>
              <label className={styles.formItemLabel} htmlFor="firm">
                Firm
              </label>
              <Field
                className={styles.formItemField}
                id="firm"
                name="firm"
                placeholder="optional"
              />
            </div>

            <Field
              as="textarea"
              className={
                !popUp
                  ? styles.formItemField + ' ' + styles.textField
                  : styles.formItemField + ' ' + styles.textFieldPopUp
              }
              id="text"
              name="text"
              type="textarea"
              placeholder="Let us know how we can help you! Please include a brief description of your project, if applicable.
          "
            />

            <div style={{ marginTop: '3.5rem' }}>
              <button type="submit" className={styles.buttonContainer}>
                <ArrowButton
                  noImage={submitedForm}
                  popUp={popUp}
                  isWhite={true}
                  name={buttonTitle}
                  fontSize={'1.6rem'}
                  imgLeftMargin={'2rem'}
                />
              </button>
            </div>

            <button type="submit" className={styles.buttonContainer}>
              <div className={classnames(styles.submitMobile, 'show-on-mobile')}>
                <ArrowButton name={buttonTitle} imgLeftMargin={'1rem'} noImage={submitedForm} />
              </div>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ReusableContactForm;
