import { Link, navigate } from 'gatsby';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useViewport from '../../../hooks/useViewport';
//import logoBlack from '../../../images/DVM_Logo_black.svg';
import logoBlack from '../../../images/portfolio/DVM_Logo_black_and_green.svg';
// import logoWhite from '../../../images/portfolio/DVM_Logo_whiteGreen.svg';
import logoWhite from '../../../images/DaVinciLogo2024.svg';
import accountIconBlack from '../../../images/icon-account-black.svg';
import accountIconFilledBlack from '../../../images/icon-account-filled-black.svg';
import accountIcon from '../../../images/icon-account.svg';
import searchIconBlack from '../../../images/icon-search-black.svg';
import searchIcon from '../../../images/icon-search.svg';
import mobileContactIconBlack from '../../../images/Mobile-Icon-Black-Contact.svg';
import mobileContactIcon from '../../../images/Mobile-Icon-White-Contact.svg';
import searchIconFilledBlack from '../../../images/search-icon-filled-black.svg';
import AskUsMobilePupup from '../../shared/ask-us-popup/AskUsMobilePopup';
import ContactUs from '../../shared/contact-us/ContactUs';
import { ExpandType } from '../../shared/enum/expand-type.enum';
import CreateAccount from '../../shared/forms/create-account/CreateAccount';
import ForgotPassword from '../../shared/forms/forgot-password/ForgotPassword';
import LogIn from '../../shared/forms/log-in/LogIn';
import ResetPassword from '../../shared/forms/reset-password/ResetPassword';
import Search from '../../shared/search/Search';
import styles from './header.module.scss';
import { useLocation } from '@reach/router';

interface Props {
  expandType: ExpandType;
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
  light?: boolean;
  isWhiteBackground?: boolean;
}
interface IsPartiallyCurrent {
  isPartiallyCurrent: boolean;
}

const Header: React.FC<Props> = ({
  expandType,
  setExpandType,
  light,
  isWhiteBackground,
}: Props) => {
  const location = useLocation();
  const { isTablet, isMobile } = useViewport();
  const { isAuthenticated } = useAuth();

  const isRootPage = location.pathname === '/';

  useEffect(() => {
    updateBodyStyles(expandType, !isTablet);
  }, [expandType, isTablet]);

  const updateBodyStyles = (type: ExpandType, enable: boolean) => {
    if (type !== ExpandType.NONE && type !== ExpandType.MENU && enable) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100vw';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  };

  const isPartiallyActive = ({ isPartiallyCurrent }: IsPartiallyCurrent) => {
    return isPartiallyCurrent && { className: styles.navLinkCrutch };
  };

  const isPartiallyActiveSlab = ({ isPartiallyCurrent }: IsPartiallyCurrent) => {
    return isPartiallyCurrent && { className: styles.navLinkCrutchSlab };
  };

  const handleBurgerClick = () => {
    setExpandType(expandType === ExpandType.MENU ? ExpandType.NONE : ExpandType.MENU);
  };

  return (
    <>
      {!isTablet && !isMobile && (
        <button
          className={
            styles.burgerButton +
            ' ' +
            styles.burgerButtonOuter +
            (expandType === ExpandType.MENU ? ` ${styles.hideButton}` : '')
          }
          onClick={handleBurgerClick}
        >
          <div
            style={{
              transform:
                expandType === ExpandType.MENU && !isTablet ? 'rotate(45deg)' : 'rotate(0)',
            }}
          ></div>
          <div
            style={{
              transform:
                expandType === ExpandType.MENU && !isTablet ? 'translateX(20px)' : 'translateX(0)',
              opacity: expandType === ExpandType.MENU && !isTablet ? '0' : '1',
            }}
          ></div>
          <div
            style={{
              transform:
                expandType === ExpandType.MENU && !isTablet ? 'rotate(-45deg)' : 'rotate(0)',
            }}
          ></div>
        </button>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isWhiteBackground ? 'white' : 'unset',
        }}
      >
        <header
          className={
            styles.header +
            (light ? '' : ` ${styles.dark}`) +
            (expandType === ExpandType.MENU ? ' menu-opened' : '') +
            (expandType !== ExpandType.NONE && expandType !== ExpandType.MENU
              ? ' ' + styles.white
              : ' ')
          }
          style={
            isRootPage
              ? {
                  height: '11rem',
                  background: expandType === ExpandType.MENU ? 'transparent' : '#3E3D3D',
                }
              : undefined
          }
        >
          {(isTablet || isMobile) && (
            <button
              className={
                styles.burgerButton +
                (expandType === ExpandType.MENU ? ` ${styles.hideButton}` : '')
              }
              onClick={handleBurgerClick}
            >
              <div
                style={{
                  transform:
                    expandType === ExpandType.MENU && !isTablet ? 'rotate(45deg)' : 'rotate(0)',
                }}
              ></div>
              <div
                style={{
                  transform:
                    expandType === ExpandType.MENU && !isTablet
                      ? 'translateX(20px)'
                      : 'translateX(0)',
                  opacity: expandType === ExpandType.MENU && !isTablet ? '0' : '1',
                }}
              ></div>
              <div
                style={{
                  transform:
                    expandType === ExpandType.MENU && !isTablet ? 'rotate(-45deg)' : 'rotate(0)',
                }}
              ></div>
            </button>
          )}
          <div className={styles.navBox}>
            <div className={styles.leftNavLinksBox}>
              <Link
                to="/slab"
                className={styles.navLink + ' ' + styles.withMargin}
                getProps={isPartiallyActiveSlab}
              >
                SLAB INVENTORY
              </Link>
              <Link
                to="/tile"
                className={styles.navLink + ' ' + styles.withMargin}
                getProps={isPartiallyActive}
                style={{ marginRight: 'calc(8rem / 2)' }}
              >
                TILE
              </Link>
              <Link to="/tile/outdoor" className={styles.navLink} getProps={isPartiallyActive}>
                OUTDOOR
              </Link>
            </div>
            <Link to="/" className={styles.logo}>
              <img
                alt="logo"
                src={
                  light && (expandType === ExpandType.NONE || expandType === ExpandType.MENU)
                    ? logoWhite
                    : logoBlack
                }
              />
            </Link>
            <div className={styles.rightNavLinksBox}>
              <Link
                to="/custom"
                className={styles.navLink + ' ' + styles.withMargin}
                activeStyle={{ color: '#979797' }}
              >
                CUSTOM
              </Link>
              <Link to="/portfolio" className={styles.navLink} getProps={isPartiallyActive}>
                PORTFOLIO
              </Link>
            </div>
          </div>
          <div className={styles.rightPanelBox}>
            <button
              style={{ ...(expandType === ExpandType.CONTACT_US && { borderRadius: '0.5rem' }) }}
              className={styles.contactUs}
              onClick={() => {
                // bodyStyleContactUs();
                setExpandType(
                  expandType === ExpandType.CONTACT_US ? ExpandType.NONE : ExpandType.CONTACT_US,
                );
              }}
            >
              CONTACT US
            </button>
            <button
              className={
                styles.accountButton +
                ' ' +
                (expandType === ExpandType.LOG_IN ||
                expandType === ExpandType.CREATE_ACCOUNT ||
                expandType === ExpandType.FORGOT_PASSWORD
                  ? styles.accountActive
                  : '')
              }
              onClick={() => {
                if (isAuthenticated) {
                  navigate('/account/favorites');
                } else {
                  // bodyStyleLogin();
                  setExpandType(
                    expandType === ExpandType.LOG_IN ||
                      expandType === ExpandType.CREATE_ACCOUNT ||
                      expandType === ExpandType.FORGOT_PASSWORD
                      ? ExpandType.NONE
                      : ExpandType.LOG_IN,
                  );
                }
              }}
            >
              <img
                alt="account icon"
                src={
                  expandType === ExpandType.LOG_IN
                    ? accountIconFilledBlack
                    : light && (expandType === ExpandType.NONE || expandType === ExpandType.MENU)
                    ? accountIcon
                    : accountIconBlack
                }
              />
            </button>
            <button
              className={
                styles.searchButton +
                ' ' +
                (expandType === ExpandType.SEARCH ? styles.searchActive : '')
              }
              onClick={() => {
                // bodyStyleSearch();
                setExpandType(
                  expandType === ExpandType.SEARCH ? ExpandType.NONE : ExpandType.SEARCH,
                );
              }}
            >
              <img
                alt="search icon"
                src={
                  expandType === ExpandType.SEARCH
                    ? searchIconFilledBlack
                    : light && (expandType === ExpandType.NONE || expandType === ExpandType.MENU)
                    ? searchIcon
                    : searchIconBlack
                }
              />
            </button>
          </div>

          <button
            className={
              styles.mobileContactIcon +
              ' ' +
              (expandType === ExpandType.SEARCH ? styles.searchActive : '')
            }
            onClick={() => {
              setExpandType(expandType === ExpandType.SEARCH ? ExpandType.NONE : ExpandType.SEARCH);
            }}
          >
            <img
              alt="search icon"
              src={
                expandType === ExpandType.SEARCH
                  ? searchIconFilledBlack
                  : light && (expandType === ExpandType.NONE || expandType === ExpandType.MENU)
                  ? searchIcon
                  : searchIconBlack
              }
            />
          </button>

          {expandType === ExpandType.SEARCH ? (
            <div className={styles.expand}>
              <Search />
            </div>
          ) : null}
          {expandType === ExpandType.CONTACT_US ? (
            <div className={styles.expand}>
              <ContactUs />
            </div>
          ) : null}
          {expandType === ExpandType.CONTACT_US_POPUP ? (
            <AskUsMobilePupup onClose={() => setExpandType(ExpandType.NONE)} />
          ) : null}
          {expandType === ExpandType.LOG_IN && !isTablet ? (
            <div className={styles.expand}>
              <LogIn setExpandType={setExpandType} />
            </div>
          ) : null}
          {expandType === ExpandType.CREATE_ACCOUNT && !isTablet ? (
            <div className={styles.expand + ' ' + styles.highExpand}>
              <CreateAccount setExpandType={setExpandType} />
            </div>
          ) : null}
          {expandType === ExpandType.FORGOT_PASSWORD && !isTablet ? (
            <div className={styles.expand}>
              <ForgotPassword setExpandType={setExpandType} />
            </div>
          ) : null}
          {expandType === ExpandType.RESET_PASSWORD && !isTablet ? (
            <div className={styles.expand}>
              <ResetPassword setExpandType={setExpandType} />
            </div>
          ) : null}
        </header>
        {/* {!isTablet || expandType === ExpandType.NONE ? (
      <div className={location.pathname === '/' ? styles.homePageCovidStyle : ''}>
        <CovidBanner expandType={expandType} />
      </div>
    ) : null} */}
        {expandType !== ExpandType.NONE && (
          <div
            className={styles.containerWithOpacity}
            onClick={() => setExpandType(ExpandType.NONE)}
          ></div>
        )}
      </div>
    </>
  );
};

export default Header;
