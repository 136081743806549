import React from 'react';
import styles from './Modal.module.scss';
import Portal from '../Portal';
import closeIcon from '../../../images/icon-close-black.svg';
import cn from 'classnames';
import useViewport from '../../../hooks/useViewport';

interface Props {
  hideClosebutton?: boolean;
  contactUs?: boolean;
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  type?: string;
  hideButtonWideView?: boolean;
  isFavoriteSaved?: boolean;
  isFavourite?: boolean;
  style?: React.CSSProperties;
}

// eslint-disable-next-line react/prop-types
export const Modal: React.FC<Props> = ({
  isOpen,
  handleClose,
  children,
  contactUs,
  type,
  hideClosebutton,
  hideButtonWideView,
  isFavoriteSaved,
  isFavourite,
  style,
}: Props) => {
  const outsideRef = React.useRef(null);

  const { isMobile } = useViewport();

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === outsideRef.current) {
      handleClose();
    }
  };

  return isOpen ? (
    <div>
      {hideClosebutton && <div className={styles.transparentDiv}></div>}
      <Portal>
        <div ref={outsideRef} className={styles.modal} onClick={closeModal}>
          <div
            className={cn(
              { [styles.modalContent]: !type && !hideClosebutton },
              { [styles.modalContentFavorite]: type },
              { [styles.thankContent]: hideClosebutton },
              { [styles.narrowContent]: isFavourite || (type && isFavoriteSaved && isMobile) },
            )}
            style={style}
          >
            <div className={styles.modalHeader}>
              {!contactUs && !isFavourite ? (
                <button
                  className={hideClosebutton ? styles.thankPopupStyleButton : styles.closeBtn}
                >
                  <div className={styles.closeBtn} onClick={handleClose}>
                    <img
                      className={cn(
                        { [styles.image]: !type && !hideButtonWideView },
                        { [styles.favoriteImage]: type },
                        { [styles.hideButton]: hideButtonWideView },
                      )}
                      src={closeIcon}
                      onClick={handleClose}
                      alt="modal image"
                    />
                    <div className={type ? styles.favoriteClose : styles.closeBtnTitle}>CLOSE</div>
                  </div>
                </button>
              ) : null}
            </div>
            {children}
          </div>
        </div>
      </Portal>
    </div>
  ) : null;
};
