import { Link } from 'gatsby';
import React, { FC, useState } from 'react';

import ArrowButton from '../arrow-button/ArrowButton';
import ContactUsPopup from '../contact-us-popup/ContactUsPopup';
import styles from './ContactUs.module.scss';

const ContactUs: FC = () => {
  const [isShowAskUsPopup, setIsShowAskUsPopup] = useState(false);
  const [isSetWhiteButtonOne, SetWhiteButtonOne] = useState(false);
  const [isSetWhiteButtonTwo, SetWhiteButtonTwo] = useState(false);

  const onClose = () => {
    setIsShowAskUsPopup(false);
  };

  return (
    <>
      <section className={styles.ContactUs}>
        <div className={styles.divider}></div>
        <Link
          to={'/schedule-visit'}
          className={styles.button1}
          onMouseEnter={() => SetWhiteButtonOne(!isSetWhiteButtonOne)}
          onMouseLeave={() => SetWhiteButtonOne(!isSetWhiteButtonOne)}
        >
          <div style={{ marginTop: '3rem' }}>
            <ArrowButton
              name={'SCHEDULE YOUR VISIT'}
              imgLeftMargin={'4rem'}
              isWhite={isSetWhiteButtonOne}
            />
          </div>
        </Link>

        <div
          className={styles.button2}
          onMouseEnter={() => SetWhiteButtonTwo(!isSetWhiteButtonTwo)}
          onMouseLeave={() => SetWhiteButtonTwo(!isSetWhiteButtonTwo)}
        >
          {isShowAskUsPopup ? (
            <div className={styles.popupContainer}>
              <ContactUsPopup onClose={onClose} />
            </div>
          ) : null}
          <div style={{ marginTop: '3rem' }}>
            <ArrowButton
              name={'ASK US ANYTHING!'}
              imgLeftMargin={'4rem'}
              onClick={() => setIsShowAskUsPopup(true)}
              isWhite={isSetWhiteButtonTwo}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
