import { ErrorMessage, useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import styles from './FieldError.module.scss';
import classnames from 'classnames';

interface Props extends PropsWithChildren<unknown> {
  name: string;
}

const FieldWithError: React.FC<Props> = ({ name, children }: Props) => {
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();

  return (
    <div className={classnames(styles.fieldError, touched[name] && errors[name] && styles.error)}>
      {children}
      <ErrorMessage name={name} />
    </div>
  );
};

export default FieldWithError;
