import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import styles from './TopAnnouncementBanner.module.scss';
import cn from 'classnames';

interface Props {
  background: boolean | undefined;
}

////////////////// background - if needed

const TopAnnouncementBanner: FC<Props> = ({ background }: Props) => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        topAnnouncementBanner {
          Color
          Is_Banner_Shown
          Is_Opened_In_New_Tab
          Link
          PrimaryText
        }
      }
    }
  `);

  const {
    strapi: {
      topAnnouncementBanner: { Is_Banner_Shown, Color, Link, PrimaryText, Is_Opened_In_New_Tab },
    },
  } = data;

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.hideBanner]: !Is_Banner_Shown,
      })}
    >
      {/* <div
        className={styles.upperBlock}
        style={background ? { backgroundColor: 'white' } : {}}
      ></div> */}
      <div className={styles.bottomBlock} style={{ backgroundColor: `${Color}` }}>
        <a href={Link} target={Is_Opened_In_New_Tab ? '_blank' : '_self'}>
          <span>{PrimaryText}</span>
        </a>
      </div>
    </div>
  );
};

export default TopAnnouncementBanner;
