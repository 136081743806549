// import { Link, navigate } from 'gatsby';
// import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
// import useAuth from '../../../hooks/useAuth';
// import accountIcon from '../../../images/icon-account.svg';
// import searchIcon from '../../../images/icon-search.svg';
// import closeButton from '../../../images/Mobile-Icon-Black-Close.svg';
// import chatIcon from '../../../images/Mobile-Icon-White-Contact.svg';
// import arrowLeft from '../../../images/back-arrow.svg';
// import { ExpandType } from '../../shared/enum/expand-type.enum';
// import styles from './MobileOverlayMenu.module.scss';
// import classnames from 'classnames';

// interface Props {
//   expandType: ExpandType;
//   setExpandType: Dispatch<SetStateAction<ExpandType>>;
// }

// const MobileOverlayMenu: React.FC<Props> = ({ expandType, setExpandType }: Props) => {
//   const [isSearchOpened, setIsSearchOpened] = useState(false);
//   const [searchText, setSearchText] = useState('');
//   const [portfolioMenuOpened, setPortfolioMenuOpened] = useState(false);
//   const { isAuthenticated } = useAuth();

//   const openAccount = () => {
//     if (isAuthenticated) {
//       navigate('/account/favorites');
//       setExpandType(ExpandType.NONE);
//     } else {
//       setExpandType(ExpandType.LOG_IN);
//     }
//   };

//   useEffect(() => {
//     const overlayInput =
//       typeof document !== undefined ? document.getElementById('overlay_search') : null;
//     const fakeInput = document.createElement('input');
//     fakeInput.setAttribute('type', 'text');
//     fakeInput.style.position = 'absolute';
//     fakeInput.style.opacity = '0';
//     fakeInput.style.height = '0';
//     fakeInput.style.fontSize = '16px'; // disable auto zoom

//     // you may need to append to another element depending on the browser's auto
//     // zoom/scroll behavior
//     document.body.prepend(fakeInput);

//     // focus so that subsequent async focus will work
//     fakeInput.focus();

//     setTimeout(() => {
//       // now we can focus on the target input
//       overlayInput?.click();

//       overlayInput?.focus();

//       // cleanup
//       fakeInput.remove();
//     }, 1000);

//     // setTimeout(() => { headerInput?.focus()}, 0)
//   }, [isSearchOpened]);

//   return (
//     <div
//       style={{ transform: expandType === ExpandType.MENU ? 'translateX(0)' : 'translateX(-100%)' }}
//       className={styles.mobileOverlayWrapper}
//     >
//       {!portfolioMenuOpened ?
//         <span className={styles.menuCopy}>MENU</span> :
//         <div className={styles.portfolioTopWrap}>
//           <button
//             className={styles.prevButton}
//             onClick={() => setPortfolioMenuOpened(false)}
//           >
//             <img src={arrowLeft} alt="close button" />
//           </button>
//           <span >Portfolio</span>
//         </div>

//       }
//       <button
//         className={styles.closeButton}
//         onClick={() => {
//           setIsSearchOpened(false);
//           setExpandType(expandType === ExpandType.MENU ? ExpandType.NONE : ExpandType.MENU);
//         }}
//       >
//         <img src={closeButton} alt="close button" />
//       </button>
//       {!portfolioMenuOpened ? (<div className={styles.menuItemsContainer}>
//         <Link className={styles.menuItem} to="/slab">
//           <span data-before="01">SLAB INVENTORY</span>
//         </Link>
//         <Link className={styles.menuItem} to="/tile">
//           <span data-before="02">TILE</span>
//         </Link>
//         <Link className={styles.menuItem} to="/tile/outdoor">
//           <span data-before="03">OUTDOOR</span>
//         </Link>
//         <Link className={styles.menuItem} to="/custom">
//           <span data-before="04">CUSTOM</span>
//         </Link>
//         <div className={styles.menuItem} onClick={() => setPortfolioMenuOpened(true)}>
//           <span data-before="05">PORTFOLIO</span>
//         </div>
//         <div className={styles.spacer}></div>
//         <Link className={styles.menuItem} to="/about">
//           <span data-before="06">ABOUT</span>
//         </Link>
//         {/* <Link className={styles.menuItem} to="/showroom">
//           <span data-before="06">SHOWROOM</span>
//         </Link> */}
//         <Link className={styles.menuItem} to="/resources">
//           <span data-before="07">RESOURCES</span>
//         </Link>
//         <Link className={styles.menuItem} to="/careers">
//           <span data-before="08">CAREERS</span>
//         </Link>
//         <div
//           className={styles.bottomActionBox}
//           onClick={() => setExpandType(ExpandType.CONTACT_US_POPUP)}
//         >
//           <img src={chatIcon} alt="contact icon" />
//           <span>Contact</span>
//         </div>
//         {isSearchOpened ? (
//           <div className={classnames(styles.bottomActionBox, styles.search)}>
//             <form
//               action="javascript:void(0);"
//               onSubmit={() => {
//                 typeof window !== 'undefined' &&
//                   (window.location.href = window.location.origin + `/search?q=${searchText}`);
//               }}
//             >
//               <input
//                 id="overlay_search"
//                 placeholder={'search'}
//                 className={styles.searchInput}
//                 onChange={(e) => setSearchText(e.target.value)}
//               />
//             </form>
//           </div>
//         ) : (
//           <div
//             className={classnames(styles.bottomActionBox)}
//             onClick={() => setIsSearchOpened(true)}
//           >
//             <img src={searchIcon} alt="search icon" />
//             <span>search</span>
//           </div>
//         )}
//         <div className={styles.bottomActionBox} onClick={openAccount}>
//           <img src={accountIcon} alt="account icon" />
//           <span>{isAuthenticated ? 'account' : 'register / log in'}</span>
//         </div>
//       </div>) :
//       (<div className={styles.menuItemsContainer}>
//         <span className={styles.menuSubtitle}>Case studies</span>
//         <Link className={styles.menuItem} to="/portfolio">
//           <span data-before="01">Residential</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/commercial">
//           <span data-before="02">Commercial</span>
//         </Link>
//         <span className={styles.menuSubtitle}>Gallery</span>
//         <Link className={styles.menuItem} to="/portfolio/gallery">
//           <span data-before="03">Kitchen</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Bathroom">
//           <span data-before="04">Bathroom</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Fireplace">
//           <span data-before="05">Fireplace</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Bar%20and%20Wine%20Rooms">
//           <span data-before="06">Bar and Wine Rooms</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Outdoor">
//           <span data-before="07">Outdoor</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Spaces">
//           <span data-before="08">Spaces</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Custom">
//           <span data-before="09">Custom</span>
//         </Link>
//         <Link className={styles.menuItem} to="/portfolio/gallery/Tile%20Inspiration">
//           <span data-before="10">Tile Inspiration</span>
//         </Link>
//       </div>)
//       }
//     </div>
//   );
// };

// export default MobileOverlayMenu;


import { Link, navigate } from 'gatsby';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import accountIcon from '../../../images/icon-account.svg';
import searchIcon from '../../../images/icon-search.svg';
import closeButton from '../../../images/Mobile-Icon-Black-Close.svg';
import chatIcon from '../../../images/Mobile-Icon-White-Contact.svg';
import arrowLeft from '../../../images/back-arrow.svg';
import { ExpandType } from '../../shared/enum/expand-type.enum';
import styles from './MobileOverlayMenu.module.scss';
import classnames from 'classnames';

interface Props {
  expandType: ExpandType;
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
}

const MobileOverlayMenu: React.FC<Props> = ({ expandType, setExpandType }: Props) => {
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [portfolioMenuOpened, setPortfolioMenuOpened] = useState(false);
  const { isAuthenticated } = useAuth();

  const openAccount = () => {
    if (isAuthenticated) {
      navigate('/account/favorites');
      setExpandType(ExpandType.NONE);
    } else {
      setExpandType(ExpandType.LOG_IN);
    }
  };

  useEffect(() => {
    const overlayInput =
      typeof document !== undefined ? document.getElementById('overlay_search') : null;
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = '0';
    fakeInput.style.height = '0';
    fakeInput.style.fontSize = '16px'; // disable auto zoom

    // you may need to append to another element depending on the browser's auto
    // zoom/scroll behavior
    document.body.prepend(fakeInput);

    // focus so that subsequent async focus will work
    fakeInput.focus();

    setTimeout(() => {
      // now we can focus on the target input
      overlayInput?.click();

      overlayInput?.focus();

      // cleanup
      fakeInput.remove();
    }, 1000);

    // setTimeout(() => { headerInput?.focus()}, 0)
  }, [isSearchOpened]);

  return (
    <div
      style={{ transform: expandType === ExpandType.MENU ? 'translateX(0)' : 'translateX(-100%)' }}
      className={styles.mobileOverlayWrapper}
    >
      {!portfolioMenuOpened ?
        <span className={styles.menuCopy}>MENU</span> :
        <div className={styles.portfolioTopWrap} onClick={() => setPortfolioMenuOpened(false)}>
          <img src={arrowLeft} alt="close button" />
          <span >Portfolio</span>
        </div>

      }
      <button
        className={styles.closeButton}
        onClick={() => {
          setIsSearchOpened(false);
          setExpandType(expandType === ExpandType.MENU ? ExpandType.NONE : ExpandType.MENU);
        }}
      >
        <img src={closeButton} alt="close button" />
      </button>
      <div className={styles.menusWrap} >
        <div 
          className={styles.menuItemsContainer} 
          style={{
            transform: `translateX(${!portfolioMenuOpened ? 0 : "-100%"})`,
          }}
        >
          <Link className={styles.menuItem} to="/slab">
            <span data-before="01">SLAB INVENTORY</span>
          </Link>
          <Link className={styles.menuItem} to="/tile">
            <span data-before="02">TILE</span>
          </Link>
          <Link className={styles.menuItem} to="/tile/outdoor">
            <span data-before="03">OUTDOOR</span>
          </Link>
          <Link className={styles.menuItem} to="/custom">
            <span data-before="04">CUSTOM</span>
          </Link>
          <div className={styles.menuItem} onClick={() => setPortfolioMenuOpened(true)}>
            <span data-before="05">PORTFOLIO</span>
          </div>
          <div className={styles.spacer}></div>
          <Link className={styles.menuItem} to="/about">
            <span data-before="06">ABOUT</span>
          </Link>
          {/* <Link className={styles.menuItem} to="/showroom">
            <span data-before="06">SHOWROOM</span>
          </Link> */}
          <Link className={styles.menuItem} to="/resources">
            <span data-before="07">RESOURCES</span>
          </Link>
          <Link className={styles.menuItem} to="/careers">
            <span data-before="08">CAREERS</span>
          </Link>
          <div
            className={styles.bottomActionBox}
            onClick={() => setExpandType(ExpandType.CONTACT_US_POPUP)}
          >
            <img src={chatIcon} alt="contact icon" />
            <span>Contact</span>
          </div>
          {isSearchOpened ? (
            <div className={classnames(styles.bottomActionBox, styles.search)}>
              <form
                action="javascript:void(0);"
                onSubmit={() => {
                  typeof window !== 'undefined' &&
                    (window.location.href = window.location.origin + `/search?q=${searchText}`);
                }}
              >
                <input
                  id="overlay_search"
                  placeholder={'search'}
                  className={styles.searchInput}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </form>
            </div>
          ) : (
            <div
              className={classnames(styles.bottomActionBox)}
              onClick={() => setIsSearchOpened(true)}
            >
              <img src={searchIcon} alt="search icon" />
              <span>search</span>
            </div>
          )}
          <div className={styles.bottomActionBox} onClick={openAccount}>
            <img src={accountIcon} alt="account icon" />
            <span>{isAuthenticated ? 'account' : 'register / log in'}</span>
          </div>
        </div>
        <div 
          className={styles.menuItemsContainer} 
          style={{
            transform: `translateX(${!portfolioMenuOpened ? 0 : "-100%"})`,
            height: "fit-content",
          }}
        >
          <span className={styles.menuSubtitle}>Case studies</span>
          <Link className={styles.menuItem} to="/portfolio">
            <span data-before="01">Residential</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/commercial">
            <span data-before="02">Commercial</span>
          </Link>
          <span className={styles.menuSubtitle}>Gallery</span>
          <Link className={styles.menuItem} to="/portfolio/gallery">
            <span data-before="03">Kitchen</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Bathroom">
            <span data-before="04">Bathroom</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Fireplace">
            <span data-before="05">Fireplace</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Bar%20and%20Wine%20Rooms">
            <span data-before="06">Bar and Wine Rooms</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Outdoor">
            <span data-before="07">Outdoor</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Spaces">
            <span data-before="08">Spaces</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Custom">
            <span data-before="09">Custom</span>
          </Link>
          <Link className={styles.menuItem} to="/portfolio/gallery/Tile%20Inspiration">
            <span data-before="10">Tile Inspiration</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileOverlayMenu;
