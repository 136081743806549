import './layout.module.scss';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import React, { CSSProperties, useEffect, useState } from 'react';
import { NotificationContainer } from 'react-notifications';
import OverlayMenu from '../overlay-menu/OverlayMenu';
import { ExpandType } from '../shared/enum/expand-type.enum';
import CreateAccount from '../shared/forms/create-account/CreateAccount';
import ForgotPassword from '../shared/forms/forgot-password/ForgotPassword';
import LogIn from '../shared/forms/log-in/LogIn';
import ResetPassword from '../shared/forms/reset-password/ResetPassword';
import Submenu from '../shared/forms/submenu/Submenu';
import Footer from './layout-parts/footer';
import Header from './layout-parts/header';
import useViewport from '../../hooks/useViewport';
import TopAnnouncementBanner from '../TopAnnouncementBanner/TopAnnouncementBanner';

import favicon from '../../images/favicon.ico';
import Helmet from 'react-helmet';
interface Props {
  children: React.ReactNode;
  bottomFloatingComponent?: React.ReactNode;
  bottomFloatingComponentStyles?: CSSProperties;
  lightNavbar?: boolean;
  isWhiteBackground?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  lightNavbar,
  bottomFloatingComponent,
  bottomFloatingComponentStyles,
  isWhiteBackground,
}: Props) => {
  const [expandType, setExpandType] = useState<ExpandType>(ExpandType.NONE);
  const location = useLocation();
  const { isTablet } = useViewport();

  const isHomePage = location?.pathname === '/';

  useEffect(() => {
    const { login, register, code } = parse(location.search);
    if (login && expandType !== ExpandType.LOG_IN) {
      setExpandType(ExpandType.LOG_IN);
    } else if (register && expandType !== ExpandType.CREATE_ACCOUNT) {
      setExpandType(ExpandType.CREATE_ACCOUNT);
    } else if (code && expandType !== ExpandType.RESET_PASSWORD) {
      setExpandType(ExpandType.RESET_PASSWORD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <TopAnnouncementBanner background={isWhiteBackground} />
      <NotificationContainer />
      <Header
        expandType={expandType}
        setExpandType={setExpandType}
        light={lightNavbar}
        isWhiteBackground={isWhiteBackground}
      />
      <OverlayMenu expandType={expandType} setExpandType={setExpandType} isHomePage={isHomePage} />
      <Submenu
        expandType={expandType}
        setExpandType={setExpandType}
        onBack={ExpandType.MENU}
        onCall={ExpandType.LOG_IN}
      >
        <LogIn setExpandType={setExpandType} />
      </Submenu>
      <Submenu
        expandType={expandType}
        setExpandType={setExpandType}
        onBack={ExpandType.LOG_IN}
        onCall={ExpandType.CREATE_ACCOUNT}
      >
        <CreateAccount setExpandType={setExpandType} />
      </Submenu>
      <Submenu
        expandType={expandType}
        setExpandType={setExpandType}
        onBack={ExpandType.LOG_IN}
        onCall={ExpandType.FORGOT_PASSWORD}
      >
        <ForgotPassword setExpandType={setExpandType} />
      </Submenu>
      <Submenu
        expandType={expandType}
        setExpandType={setExpandType}
        onBack={ExpandType.LOG_IN}
        onCall={ExpandType.RESET_PASSWORD}
      >
        <ResetPassword setExpandType={setExpandType} />
      </Submenu>

      {((expandType === ExpandType.NONE && isTablet) ||
        (expandType === ExpandType.SEARCH && isTablet) ||
        (expandType !== ExpandType.MENU && !isTablet)) && (
        <React.Fragment>
          <main style={{ marginTop: isHomePage ? '11rem' : undefined }}>{children}</main>
          {bottomFloatingComponent ? (
            <div
              style={{
                ...{
                  zIndex: 10,
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.199929)',
                },
                ...bottomFloatingComponentStyles,
              }}
            >
              {bottomFloatingComponent}
            </div>
          ) : (
            ''
          )}
          <Footer />
        </React.Fragment>
      )}
    </>
  );
};

export default Layout;
