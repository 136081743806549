import React, { Dispatch, SetStateAction } from 'react';

import styles from './Submenu.module.scss';
import closeButton from '../../../../images/Mobile-Icon-Black-Close.svg';
import backArrow from '../../../../images/white-arrow-left.svg';
import { ExpandType } from '../../../shared/enum/expand-type.enum';
import useViewport from '../../../../hooks/useViewport';

interface Props {
  expandType: ExpandType;
  setExpandType: Dispatch<SetStateAction<ExpandType>>;
  children: React.ReactNode;
  onBack: ExpandType;
  onCall: ExpandType;
}

const Submenu: React.FC<Props> = ({
  children,
  expandType,
  setExpandType,
  onBack,
  onCall,
}: Props) => {
  const { isTablet } = useViewport();

  let backButton: string | undefined = undefined;
  if (onBack === ExpandType.LOG_IN) {
    backButton = 'BACK TO LOG IN';
  } else if (onBack === ExpandType.MENU) {
    backButton = 'BACK TO MENU';
  }

  return (
    <div
      style={{
        transform: expandType === onCall && isTablet ? 'translateX(0)' : 'translateX(-100%)',
      }}
      className={styles.mobileOverlayWrapper}
    >
      <button
        className={styles.topButton}
        onClick={() => {
          setExpandType(onBack);
        }}
      >
        <img alt="back" src={backArrow} className={styles.backArrow} />
        {backButton}
      </button>
      <button
        className={styles.closeButton}
        onClick={() => {
          setExpandType(ExpandType.NONE);
        }}
      >
        <img src={closeButton} alt="close button" />
      </button>

      {children}
    </div>
  );
};

export default Submenu;
