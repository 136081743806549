import React, { useState } from 'react';
import mobileContactIcon from '../../../images/Mobile-Icon-Black-Contact.svg';
import closeIcon from '../../../images/close-black.svg';
import iconBlack from '../../../images/search-right-arrow-black.svg';
import styles from './AskUsMobilePopup.module.scss';
import ContactUsPopup from '../contact-us-popup/ContactUsPopup';
import { Link } from 'gatsby';

interface Props {
  onClose: () => void;
}

const AskUsMobilePupup: React.FC<Props> = ({ onClose }: Props) => {
  const [isShowContactUs, setIsShowContactUs] = useState(false);

  return (
    <div className={styles.AskUsMobilePopup}>
      <div className={styles.headButtons}>
        <img alt="contact icon" src={mobileContactIcon} />
        <img alt="close icon" src={closeIcon} onClick={onClose} />
      </div>

      <div className={styles.head + ' ' + styles.delimiter}>CONTACT US</div>

      <Link to={'/schedule-visit'} className={styles.btn + ' ' + styles.delimiter}>
        <div>PLAN YOUR VISIT</div>
        <img alt="contact icon" src={iconBlack} />
      </Link>
      <div
        className={styles.btn + ' ' + styles.delimiter}
        onClick={() => setIsShowContactUs(!isShowContactUs)}
      >
        <div>ASK US ANYTHING</div>
        <img alt="contact icon" src={iconBlack} />
      </div>

      {isShowContactUs ? (
        <div className={styles.contactUsPopup}>
          <ContactUsPopup onBack={() => setIsShowContactUs(!isShowContactUs)} onClose={onClose} />
        </div>
      ) : null}
    </div>
  );
};

export default AskUsMobilePupup;
